import lottie, { type AnimationItem } from 'lottie-web'

export function useAnimation(boxRef: Ref<Element | null>, animationData: any) {
  const animation = ref<AnimationItem | null>(null)
  function setupAnimation() {
    if (boxRef.value) {
      animation.value = lottie.loadAnimation({
        container: boxRef.value,
        animationData,
      })
      animation.value?.play?.()
    }
  }

  onMounted(
    setupAnimation,
  )
}
